<template>
  <div class="my_notice">
    <div class="main">
      <div class="top_box_search">
        <div class="search">
          <el-input
            v-model="search_value"
            @keyup.enter.native="search_notice"
            placeholder="请输入内容"></el-input>
          <div class="search_box">
            <i class="el-icon-search" @click="search_notice"></i>
          </div>
        </div>
        <div
          class="new_add"
          @click="dialogFormVisible = true"
          v-if="notice_type_active === 1">
          新建通知
        </div>
      </div>
      <ul class="tab">
        <li
          :class="{ active: item.id === notice_type_active }"
          v-for="item in notice_type"
          :key="item.id"
          @click="change_notice(item.id)">
          {{ item.name }}
        </li>
      </ul>
      <main>
        <div class="release_status" v-if="notice_type_active === 1">
          <div class="top_box">
            <ul class="tab">
              <li
                :class="{ active: item.id == notice_release_status_active }"
                v-for="item in notice_release_status"
                :key="item.id"
                @click="change_release(item.id)">
                {{ item.name }}
              </li>
            </ul>
            <div class="info_box">
              <p>未发布(共{{ is_release_0 }}条)</p>
              <p>已发布(共{{ is_release_1 }}条)</p>
              <p>全部(共{{ total }}条)</p>
              <div class="del_btn" @click="batch_del">删除</div>
            </div>
          </div>
          <!-- @selection-change="handleSelectionChange" -->
          <el-table
            :data="tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="接收人" width="180">
              <template slot-scope="scope" v-if="scope.row.subsidiarytable">
                {{
                  scope.row.subsidiarytable.level.name +
                  scope.row.subsidiarytable.classes.name
                }}
              </template>
            </el-table-column>
            <el-table-column prop="title" label="标题" width="180">
            </el-table-column>
            <el-table-column
              prop="content"
              label="概要"
              width="300"
              align="center">
            </el-table-column>
            <el-table-column prop="create_time" label="时间" align="center">
            </el-table-column>
            <el-table-column prop="is_release" label="发布状态" align="center">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.is_release == 1"
                  type="success"
                  effect="dark"
                  >已发布</el-tag
                >
                <el-tag v-else type="danger" effect="dark">未发布</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="上架状态" align="center">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.status == 1"
                  type="success"
                  effect="dark"
                  >上架</el-tag
                >
                <el-tag v-else type="danger" effect="dark">下架</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="120" align="center">
              <!-- slot-scope="scope" -->
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleRepublish(scope.row)"
                  v-if="scope.row.is_release != 1"
                  >重新发布</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleShelves(scope.row)"
                  >{{ scope.row.status == 1 ? "下架" : "上架" }}</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="own_notice(scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="del_notice(scope.row.id)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="chang_page">
          </el-pagination>
        </div>

        <div class="release_status" v-if="notice_type_active === 2">
          <div class="top_box">
            <ul class="tab">
              <li
                :class="{ active: item.id === notice_receive_status_active }"
                v-for="item in notice_receive_status"
                :key="item.id"
                @click="change_receive(item.id)">
                {{ item.name }}
              </li>
            </ul>
            <div class="info_box">
              <p>未查看(共{{ is_receive_0 }}条)</p>
              <p>已查看(共{{ is_receive_1 }}条)</p>
              <p>全部(共{{ total }}条)</p>
              <div class="del_btn" @click="batch_del">删除</div>
            </div>
          </div>
          <el-table
            :data="tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="admin_name" label="分类" width="150">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="200">
            </el-table-column>
            <el-table-column prop="content" label="概要" width="300">
            </el-table-column>
            <el-table-column prop="create_time" label="时间"> </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.status == 1"
                  type="success"
                  effect="dark"
                  >已读</el-tag
                >
                <el-tag v-else type="danger" effect="dark">未读</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="receive_notice_detailed(scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="del_notice(scope.row.id)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="chang_page">
          </el-pagination>
        </div>
      </main>
      <!-- 新建通知 -->
      <el-dialog title="新建通知" :visible.sync="dialogFormVisible" width="40%">
        <el-form ref="forms" :model="form" label-width="80px">
          <el-form-item label="年级">
            <el-col :span="11">
              <el-select
                v-model="form.level_id"
                placeholder="请选择年级"
                @change="changeLevel">
                <el-option
                  v-for="item in level_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-col>
            <el-col class="line" :span="2">班级</el-col>
            <el-col :span="11">
              <el-select v-model="form.classes_id" placeholder="请选择班级">
                <el-option
                  v-for="item in class_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="时间">
            <el-radio-group v-model="timing">
              <el-radio label="1">立刻</el-radio>
              <!-- <el-radio label="0">定时</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="选择时间" v-show="timing == 0">
          <el-date-picker
            v-model="form.release_time"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
          >
          </el-date-picker>
        </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="onSubmit(1)">立即创建</el-button>
            <el-button @click="onSubmit(0)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 自己发布详情 -->
      <el-dialog title="通知详情" :visible.sync="dialog_ontice" width="35%">
        <div class="ontice_details" v-if="fb_notice_information">
          <div class="details_title">
            {{ fb_notice_information.title }}
            <span> 已读人数：{{ fb_notice_information.count }}人 </span>
          </div>
          <div class="class_time" v-if="fb_notice_information.subsidiarytable">
            <div class="class_name">
              <p>接收班级</p>
              <div class="class_box">
                {{
                  fb_notice_information.subsidiarytable.level.name +
                  " " +
                  fb_notice_information.subsidiarytable.classes.name
                }}
              </div>
            </div>
            <div class="time_name">
              <p>发布时间</p>
              <div class="class_box">
                {{ fb_notice_information.release_time }}
              </div>
            </div>
          </div>
          <div class="content_text">
            {{ fb_notice_information.content }}
          </div>
          <p class="hui_fu">回复 {{ reply_total }}</p>
          <ul>
            <li v-for="item in reply_lists" :key="item.id">
              <img src="@/assets/img/02.png" alt="" />
              <div>
                <p>
                  {{ item.usertable.name }} <span>{{ item.create_time }}</span>
                </p>
                <p>{{ item.content }}</p>
              </div>
            </li>
          </ul>
          <div class="btn_box">
            <div class="del_btn" @click="del_notice(false)">删除</div>
            <div
              class="again_btn"
              @click="handleRepublish(fb_notice_information)"
              v-if="fb_notice_information.is_release != 1">
              重新发布
            </div>
            <div class="again_btn" v-else @click="again_notice">再次发布</div>
          </div>
        </div>
      </el-dialog>
      <!-- 接收通详情 -->
      <el-dialog title="通知详情" :visible.sync="dialog_js_ontice" width="35%">
        <div class="js_notice">
          <div class="time_box">
            <p class="p1">发布时间:</p>
            <p class="p2">{{ receive_notice_info.create_time }}</p>
          </div>
          <div class="title_box">
            <p>{{ receive_notice_info.title }}</p>
            <div class="fenlei">{{ receive_notice_info.admin_name }}</div>
          </div>
          <div class="content_box">
            <p>通知内容</p>
            <div class="notice_con">
              {{ receive_notice_info.content }}
            </div>
            <!-- <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input> -->
          </div>
          <div class="btn_box">
            <div class="del_btn" @click="dialog_js_ontice = false">取消</div>
            <!-- <div class="again_btn" @click="reply_notice">发送</div> -->
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search_value: "",
      notice_type: [
        { id: 1, name: "发布通知" },
        { id: 2, name: "接收通知" },
      ], // 通知类型
      notice_type_active: 2,
      notice_release_status: [
        { id: 0, name: "未发布通知" },
        { id: 1, name: "已发布通知" },
        { id: 3, name: "全部通知" },
      ], // 发布状态
      notice_release_status_active: 3,
      notice_receive_status: [
        { id: 0, name: "未查看通知" },
        { id: 1, name: "已查看通知" },
        { id: 2, name: "全部通知" },
      ], // 接收状态
      notice_receive_status_active: 2,
      tableData: [],
      total: 0,
      page: 1,
      page_size: 8,
      multipleSelection: [],
      //新建通知
      dialogFormVisible: false,
      form: {
        title: "",
        content: "",
        level_id: "",
        classes_id: "",
        is_release: "",
        release_time: "",
      },
      timing: "1",
      level_list: [], // 年级列表
      class_list: [],
      // 通知详情
      dialog_ontice: false,
      dialog_js_ontice: false,
      receive_notice_info: {},
      fb_notice_information: null,
      reply_page: 1,
      reply_per_page: 10,
      reply_lists: [],
      reply_total: 0,
      del_ids: [],
      textarea: "",
      is_release_0: "",
      is_release_1: "",
      is_receive_0: "",
      is_receive_1: "",
    };
  },
  created() {
    this.get_level_list();
    if (this.notice_type_active == 1) {
      this.get_notice_list();
    } else {
      this.get_receive_notice();
    }
    // this.get_class_list();
    this.get_notice_release_count(); // 发布统计
    this.get_api_notice_receive_count();
  },
  methods: {
    // 通知类型
    change_notice(id) {
      this.notice_type_active = id;
      this.page = 1;
      if (id === 1) {
        this.get_notice_list();
      } else {
        this.get_receive_notice();
      }
    },
    // 发布状态
    change_release(id) {
      this.page = 1;
      this.notice_release_status_active = id;
      this.get_notice_list();
    },
    // 发布通知列表
    async get_notice_list() {
      let is_release =
        this.notice_release_status_active >= 3
          ? ""
          : this.notice_release_status_active;
      const res = await this.axios.get(
        `/notice?is_release=${is_release}&page=${this.page}&per_page=${this.page_size}`
      );
      if (res.code === 1) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    change_receive(id) {
      this.notice_receive_status_active = id;
      this.page = 1;
      this.get_receive_notice();
    },
    async get_receive_notice() {
      let status =
        this.notice_receive_status_active >= 2
          ? ""
          : this.notice_receive_status_active;
      const res = await this.axios.get(
        `/receive/notice?status=${status}&page=${this.page}&per_page=${this.page_size}`
      );
      if (res.code === 1) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    // 触发分页
    chang_page(val) {
      this.page = val;
      if (this.notice_type_active == 1) {
        this.get_notice_list();
      } else {
        this.get_receive_notice();
      }
    },
    // 获取年级列表
    async get_level_list() {
      const res = await this.axios.get("/level/classes");
      if (res.code === 1) {
        this.level_list = res.data;
      }
    },
    // 选择年级
    changeLevel(val) {
      this.class_list = [];
      let list = this.level_list.filter((item) => item.id === val)[0].classes;
      list.forEach((item) => {
        this.class_list.push({ id: item.id, name: item.name });
      });
    },
    // 新建通知
    async onSubmit(val) {
      // val = 1 发布
      // val = 2 保存
      // timimg = 1 立即发布  =2 定时发布
      if (val === 1) {
        this.form.is_release = 1;
        if (this.timing == 1) {
          let time = this.moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
          this.form.release_time = time;
        }
      } else if (val === 0) {
        this.form.is_release = 0;
        if (this.timing == 1) {
          let time = this.moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
          this.form.release_time = time;
        }
      }
      const res = await this.axios.post("/notice", {
        row: this.form,
      });

      if (res.code === 1) {
        this.$message.success(res.msg);
        this.page = 1;
        this.timing = "1";
        this.get_notice_list();
        Object.keys(this.form).forEach((key) => {
          this.form[key] = "";
        });
        this.timing = "1";
        this.dialogFormVisible = false;
        this;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 再次发布
    async again_notice() {
      let again_form = {
        level_id: this.fb_notice_information.subsidiarytable.level_id,
        classes_id: this.fb_notice_information.subsidiarytable.classes_id,
        title: this.fb_notice_information.title,
        content: this.fb_notice_information.content,
        release_time: this.moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        is_release: 1,
      };
      const res = await this.axios.post(`/notice`, {
        row: again_form,
      });
      if (res.code === 1) {
        this.$message.success("再次发布成功");
        this.get_notice_list();
        this.dialog_ontice = false;
      } else {
        this.$message.error("再次发布失败");
      }
    },

    // 重新发布
    async handleRepublish(row) {
      const that = this;
      const params = {
        timimg: 1,
        level_id: row.subsidiarytable.level_id,
        classes_id: row.subsidiarytable.classes_id,
        title: row.title,
        content: row.content,
        release_time: this.moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        is_release: 1,
      };

      this.axios
        .post(`/notice`, {
          row: params,
        })
        .then((res) => {
          if (res.code === 1) {
            this.axios
              .post(`/notice/delete/notices`, {
                row: {
                  ids: [row.id],
                },
              })
              .then(() => {
                this.getList();
                this.dialog_ontice = false;
                this.$message.success("再次发布成功");
              });
          } else {
            this.$message.error("再次发布失败");
          }
        });
    },

    // 上下架
    handleShelves(record) {
      const that = this;
      const row = {
        is_release: record.is_release,
        status: record.status == 1 ? 0 : 1,
      };

      this.$confirm(
        `是否${record.status == 1 ? "下架" : "上架"}此通知?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await that.axios.post(`/notice/sold_out/${record.id}`, {
            row,
          });

          console.log(res, "res");

          if (res.code == 1) {
            that.$message.success(
              `${record.status == 1 ? "下架" : "上架"}成功`
            );
          }

          that.getList();
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: `已取消${record.status == 1 ? "下架" : "上架"}`,
          });
        });
    },

    getList() {
      if (this.notice_type_active == 1) {
        this.get_notice_list();
      } else {
        this.get_receive_notice();
      }
    },

    // 搜索
    search_notice() {
      this.get_notice_list();
    },
    // 删除单个
    del_notice(id) {
      if (!id) {
        id = this.fb_notice_information.id;
        console.log(id);
      }
      this.$confirm("是否删除此通知?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del_ids = [id];
          if (this.notice_type_active === 1) {
            this.dele_fs_notice();
          } else {
            this.dele_js_notice();
          }
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消删除" });
        });
    },
    // 批量选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.del_ids = [];
      this.multipleSelection.forEach((item) => {
        this.del_ids.push(item.id);
      });
    },
    batch_del() {
      if (this.multipleSelection.length > 0) {
        this.$confirm("此操作将永久删除该通知, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.notice_type_active === 1) {
              this.dele_fs_notice();
            } else {
              this.dele_js_notice();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.warning("请选择需要删除的通知");
      }
    },
    // 删除发送通知
    async dele_fs_notice() {
      const res = await this.axios.post(`/notice/delete/notices`, {
        row: {
          ids: this.del_ids,
        },
      });
      if (res.code === 1) {
        this.$message.success("删除成功");
        this.get_notice_list();
        this.dialog_ontice = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 删除接收通知
    async dele_js_notice() {
      try {
        const res = await this.axios.post(`/notice/delete/receive/notices`, {
          row: {
            ids: this.del_ids,
          },
        });
        if (res.code === 1) {
          this.$message.success("删除成功");
          this.get_receive_notice();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error("删除失败,请稍后再试");
      }
    },

    // 发布通知详情
    async own_notice(row) {
      console.log(row);
      this.dialog_ontice = true;
      this.fb_notice_information = row;
      this.reply_list(row.id);
      // const res = await this.axios.get(`/message/info?message_id=${id}`)
    },
    async reply_list(id) {
      const res = await this.axios.get(
        `/receives/notice/${id}?page=${this.reply_page}&per_page=${this.reply_per_page}`
      );
      if (res.code === 1) {
        this.reply_total = res.data.total;
        this.reply_lists = res.data.data;
      }
    },
    // 接收通知详情
    receive_notice_detailed(row) {
      this.receive_notice_info = row;
      this.dialog_js_ontice = true;
      this.toread(row.id);
    },
    // 回复内容
    async reply_notice() {
      try {
        const res = await this.axios.post(
          `/receive/notice/reply/${this.receive_notice_info.id}`,
          {
            row: {
              content: this.textarea,
            },
          }
        );
        if (res.code === 1) {
          this.dele_fs_notice = false;
          this.$message.success("发送成功");
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error("请求失败,请联系管理员", error);
      }
    },
    async toread(id) {
      const res = await this.axios.get(`/notice/${id}`);
      if (res.code === 1) {
        this.get_receive_notice();
      }
    },
    // 发布未发布
    async get_notice_release_count() {
      const res = await this.axios.get("/notice/release/count");
      if (res.code === 1) {
        this.is_release_1 = res.data.is_release_1;
        this.is_release_0 = res.data.is_release_0;
      }
    },
    async get_api_notice_receive_count() {
      const res = await this.axios.get("/notice/receive/count");
      console.log(res);
      if (res.code === 1) {
        this.is_receive_0 = res.data.is_receive_0;
        this.is_receive_1 = res.data.is_receive_1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.my_notice {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
  }
  .top_box_search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .search {
      position: relative;
      width: 327px;
      // margin-top: 20px;
      margin-bottom: 20px;
      .search_box {
        width: 40px;
        height: 40px;
        background: #516cf7;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 0;
        i {
          width: 100%;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          color: #fff;
        }
      }
    }
    .new_add {
      width: 100px;
      height: 40px;
      background: #516cf7;
      border-radius: 10px;
      text-align: center;
      font-size: 15px;
      color: #fff;
      line-height: 41px;
    }
  }

  .tab {
    display: flex;
    li {
      border-radius: 5px;
      padding: 8px;
      color: #fff;
      background: #cccccc;
      margin-right: 15px;
      cursor: pointer;
    }
    .active {
      background: #516cf7;
    }
  }
  main {
    padding-top: 20px;
    .top_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info_box {
        display: flex;
        align-items: center;
        color: #999999;
        p {
          font-size: 15px;
          margin-right: 20px;
        }
        .del_btn {
          padding: 0 5px;
          height: 25px;
          border-radius: 5px;
          background: #ff7478;
          border: 1px solid #d91c24;
          color: #fff;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
    .el-table {
      margin-top: 20px;
      /deep/.cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    // .el-pagination {
    //   position: absolute;
    //   right: 20px;
    //   bottom: 20px;
    // }
  }
  .ontice_details {
    .details_title {
      font-size: 16px;
      span {
        color: #5fa7ff;
        font-size: 14px;
      }
    }
    .class_time {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      .class_name {
        p {
          font-size: 15px;
          margin-bottom: 10px;
        }
        .class_box {
          padding: 10px;
          background: #f2f2f2;
          color: #999999;
          border-radius: 10px;
        }
      }
      .time_name {
        p {
          font-size: 15px;
          margin-bottom: 10px;
        }
        .class_box {
          padding: 10px;
          background: #f2f2f2;
          color: #999999;
          border-radius: 10px;
        }
      }
    }
    .content_text {
      border-radius: 10px;
      background: #f2f2f2;
      color: #999999;
      padding: 20px;
      margin-top: 20px;
    }
    .hui_fu {
      font-size: 16px;
      margin-top: 20px;
    }
    ul {
      margin-top: 40px;
      max-height: 160px;
      overflow-y: auto;
      li {
        margin-top: 10px;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #999999;
        img {
          width: 64px;
          height: 64px;
          margin-right: 40px;
        }
        p {
          line-height: 32px;
        }
      }
    }
  }
  .js_notice {
    .time_box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .p1 {
        font-size: 18px;
        color: #666666;
        margin-right: 10px;
      }
      .p2 {
        font-size: 15px;
        color: #999999;
      }
    }
    .title_box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-size: 25px;
        color: #666666;
        margin-right: 20px;
      }
      .fenlei {
        min-width: 70px;
        padding-left: 10px;
        padding-right: 10px;
        height: 25px;
        border-radius: 16px;
        font-size: 18px;
        color: #fff;
        line-height: 25px;
        text-align: center;
        background: linear-gradient(
          90deg,
          #86ddfe 0%,
          #7ccdfe 34%,
          #64a6ff 99%
        );
      }
    }
    .content_box {
      p {
        font-size: 18px;
        color: #666666;
      }
      .notice_con {
        color: #999999;
        min-height: 214px;
        background: #f2f2f2;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }
  }
  .btn_box {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .del_btn {
      margin-right: 50px;
      width: 120px;
      height: 30px;
      background: linear-gradient(
        90deg,
        #ffed94 0%,
        #fcd477 22%,
        #f5952e 70%,
        #f16d00 99%
      );
      border-radius: 20px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      cursor: pointer;
    }
    .again_btn {
      width: 120px;
      height: 30px;
      background: #516cf7;
      border-radius: 20px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      cursor: pointer;
    }
  }
  .release_status {
    // position: relative;
    // background-color: pink;
    height: 60%;
    // background-color: pink;
    .el-pagination {
      // position: absolute;
      // right: 20px;
      // bottom: 20px;
      float: right;
      margin-top: 40px;
    }
  }
}
</style>
